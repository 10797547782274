<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <ayl-table :table="table" @selection-change="onSelectionChange">
          <div slot="ctrl-button">
            <el-button class="btn" style="margin-left: 20px" @click="onAdd">+ 新增</el-button>
            <el-button plain style="float:right" @click="onDelete" :loading="btnLoading">删除</el-button>
            <!-- <el-button plain style="float:right" @click="onExport">导出</el-button> -->
          </div>
        </ayl-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const vm = this;
    return {
      nav: [{ name: "市政道路" }],
      btnLoading: false,
      selectionData: [], //勾选的数据
      table: {
        api: vm.$api_hw.municipalRoad_list,
        query: {
          bidId: null,
          queryContent: null
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "cascader_radio",
            title: "标段名称",
            model: "bidId",
            placeholder: "请选择",
            option: [],
            width: "180px"
          },
          {
            type: "input",
            model: "queryContent",
            placeholder: "市政道路类型"
          }
        ],
        columns: [
          {
            type: "selection",
            width: "50px"
          },
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "市政道路名称",
            key: "roadName",
            width: "200px",
            showTooltip: true,
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true
                    },
                    on: {
                      click: vm.onDetail.bind(this, ctx.row.roadId)
                    }
                  },
                  ctx.row.roadName || "- -"
                )
              ]);
            }
          },
          {
            title: "所属标段",
            key: "bidName"
          },
          {
            title: "养护企业",
            key: "bidMaintainUnit"
          },
          {
            title: "起止位置",
            key: "startStopPosition"
          },
          {
            title: "是否绘制",
            render(h, ctx) {
              return h("span",         
                  ctx.row.isDrawn==1? "是":"否");
            }
          },
          {
            title: "车道数(条)",
            key: "laneNum"
          },
          {
            title: "长度(m)",
            key: "mainRoadLen"
          },
          {
            title: "主车道面积(m²)",
            key: "mainRoadArea"
          },
          {
            title: "辅道(m²)",
            key: "sideRoadArea"
          },
          {
            title: "门店门前(m²)",
            key: "storeFrontArea"
          },
          {
            title: "人行道(m²)",
            key: "sideWalkArea"
          },
          {
            title: "地下通道(m²)",
            key: "underpassArea"
          },
          {
            title: "水沟面积(m²)",
            key: "ditchArea"
          },
          {
            title: "保洁等级",
            key: "cleanLevelDesc"
          },
          {
            title: "是否机扫",
            render(h, ctx) {
              return h("span",         
                  ctx.row.isSweeper==1? "是":"否");
            }
          },
          {
            title: "操作",
            width: "100px",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true
                    },
                    on: {
                      click: vm.onEdit.bind(this, ctx.row.roadId)
                    }
                  },
                  "编辑"
                )
              ]);
            }
          }
        ]
      }
    };
  },
  methods: {    
    onEdit(id) {
      this.$router.push({
        path: "./edit",
        query: {
          id: id
        }
      });
    },
    onExport() {},
    onDetail(id) {
      this.$router.push({
        path:"./info",
        query:{id:id}
      });
    },
    async onRefresh() {
      console.log("---------onRefresh");
      await this.$search(this.table);
    },
    onSelectionChange(i) {
      this.selectionData = i.map(d => d.roadId);
    },
    async onDelete() {
      let self = this;
      if (self.selectionData.length === 0) {
        self.$message.warning("请选中至少 1 项！");
        return;
      } else {
        self.$msgbox
          .confirm("确定要将选中的项删除吗？", "提示", { type: "warning" })
          .then(async function() {
            self.btnLoading = true;
            try {
              await self.$api_hw.municipalRoad_delete({
                idList :self.selectionData
              });
              self.$notify({
                title: "成功",
                message: "操作成功",
                type: "success"
              });
              self.$nextTick(function() {
                self.$search(this.table);
              });
            } catch (e) {
              // console.error(e);
            }
          }).catch((e)=>{});
        self.btnLoading = false;
      }
    },
    onAdd() {
      this.$router.push({
        path: "./add"
      });
    }
  },
  async mounted() {
    let _this = this;

    await _this.$search(this.table);

    _this.table.searchData[0].option = await _this.$api_hw.common_getBidNameAndIdList(
      {}
    );

    BUS.$on(BUSEVENT.REFRESH_MUNICIPAL_ROAD_MANAGEMENT, () => {
      _this.onRefresh();
    });
  }
};
</script>

<style lang='sass' scoped>
.btn
  width: 64px
  height: 28px
  border: 1px solid rgba(26,188,156,1)
  border-radius: 4px
  color: #1ABC9C
  
  .drawer-main
    margin: 5px 15px
    .drawer-btn
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(237,237,237,1)
      border-radius: 2px
      outline: none
      border: 0
      &:hover
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
      &:before
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
    .drawer-btn1
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(26,188,156,1)
      border-radius: 2px
      outline: none
      border: 1px solid transparent
      color: #fff

  .drawer-contract
    margin: 30px 15px 10px
    .drawer-contract-item
      width: 100%
      .drawer-contract-span
        color: #2E3033
    .drawer-contract-item1
      width: 48%
      .drawer-contract-span
        color: #2E3033
  .drawer-hr
    width: 100%
    height: 1px
    background: rgba(232,232,232,1)
  .drawer-map
    margin: 20px 15px 10px
    .drawer-map-title
      color: rgba(46,48,51,1)
  /deep/.el-drawer__header 
      margin-bottom: 15px
      padding-left: 15px
      color: #222
      font-size: 16px
  /deep/.el-button--success 
      color: #FFFFFF
      background-color: rgba(26,188,156,1)
      border-color: rgba(26,188,156,1)

</style>
